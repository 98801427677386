import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex align-items-center gap-3 mb-3" }
const _hoisted_2 = { class: "flex align-items-center gap-3" }
const _hoisted_3 = {
  key: 0,
  class: "error-message"
}
const _hoisted_4 = { class: "flex justify-content-center gap-2 mb-3 mt-5" }
const _hoisted_5 = { class: "login__text flex justify-content-center mb-3" }
const _hoisted_6 = { class: "login__text" }

import { ref } from 'vue';
import FitnesService from '@/services/FitnesService';
import IAuthPayload from '@/types/IAuthPayload';
import { useAuthStore } from '@/store/auth';
import { useConfigStore } from '@/store/config';


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginPopup',
  emits: [
  'openForgotPassPopup',
  'openRegisterPopup',
  'hide',
],
  setup(__props, { emit: __emit }) {

const authStore = useAuthStore();

const emits = __emit;
const showElement = ref(true);
const clickForgotPass = () => {
  emits('openForgotPassPopup');
};

const clickRegister = () => {
  emits('openRegisterPopup');
};

const hide = () => {
  emits('hide');
};

const errorAuth = ref(false);
const phone = ref();
const password = ref();

const signIn = async () => {
  const localPhone = phone.value.replace(/[()\-\+\s]/g, '');
  const payload: IAuthPayload = {
    phone: localPhone,
    password: password.value,
  };

  const configStore = useConfigStore();
  const res = await FitnesService.auth(configStore.integration_code, payload);
  const { data, result } = res.data;
  if (result) {
    authStore.setUserToken(data.user_token);
    hide();
  } else {
    errorAuth.value = true;
  }
};

return (_ctx: any,_cache: any) => {
  const _component_InputMask = _resolveComponent("InputMask")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_prime_dialog = _resolveComponent("prime-dialog")!

  return (_openBlock(), _createBlock(_component_prime_dialog, {
    visible: showElement.value,
    "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((showElement).value = $event)),
    class: "login",
    modal: "",
    header: "Войти",
    style: { width: '25rem' },
    onHide: _cache[6] || (_cache[6] = ($event: any) => (hide()))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[7] || (_cache[7] = _createElementVNode("label", {
          for: "phone",
          class: "font-semibold w-6rem"
        }, "Телефон", -1)),
        _createVNode(_component_InputMask, {
          id: "phone",
          modelValue: phone.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((phone).value = $event)),
          mask: "+9(999)999-99-99",
          class: "flex-auto",
          autocomplete: "off"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _cache[8] || (_cache[8] = _createElementVNode("label", {
          for: "password",
          class: "font-semibold w-6rem"
        }, "Пароль", -1)),
        _createVNode(_component_InputText, {
          id: "password",
          modelValue: password.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
          type: "password",
          class: "flex-auto",
          autocomplete: "off"
        }, null, 8, ["modelValue"])
      ]),
      (errorAuth.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, " Проверьте логин и пароль "))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          class: "fit-btn-2",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (signIn()))
        }, "Войти")
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("a", {
          href: "#",
          class: "login__link",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (clickForgotPass()))
        }, "Забыли пароль?")
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[9] || (_cache[9] = _createTextVNode(" Если у вас еще нет пароля для входа, вам нужно ")),
        _createElementVNode("a", {
          href: "#",
          class: "login__link",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (clickRegister()))
        }, "зарегистрироваться")
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})