import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "schedule"
}
const _hoisted_2 = { class: "schedule__left" }
const _hoisted_3 = { class: "schedule__date" }
const _hoisted_4 = { class: "schedule__day" }
const _hoisted_5 = { class: "schedule__date-inner" }
const _hoisted_6 = { class: "schedule__month" }
const _hoisted_7 = { class: "schedule__week-day" }
const _hoisted_8 = { class: "schedule__right" }
const _hoisted_9 = { class: "schedule__title" }
const _hoisted_10 = { class: "schedule__range" }
const _hoisted_11 = { class: "schedule__wrap-time" }
const _hoisted_12 = ["onClick"]

import { useScheduleStore } from '@/store/schedule';
import { useTariffsStore } from '@/store/tariffs';
import { useConfigStore } from '@/store/config';
import IOffer from '@/types/IOffer';


export default /*@__PURE__*/_defineComponent({
  __name: 'ScheduleBox',
  setup(__props) {

const configStore = useConfigStore();
const scheduleStore = useScheduleStore();
const tariffsStore = useTariffsStore();

const getOffers = (startTime: string, endTime: string) => {
  const allOffers: Array<IOffer> = scheduleStore.schedule.offers;
  const result: Array<IOffer> = allOffers.filter((offer) => {
    if(
      offer.startTime
      >=
      startTime
      &&
      offer.startTime
      <
      endTime
    ) {
      return true;
    } else {
      return false;
    }
  });
  return result;
};

const clickTime = (offer: IOffer) => {
  //TODO
  // if (!offer.isActive) return;
  console.log('offer', offer);
  tariffsStore.setAvailableTariffs(
    `${offer.startDate}T${offer.startTime}`,
  );
  // showElements.value.showTariffBtn = true;
  // bookingStore.selectedTime = offer.startTime;
};

return (_ctx: any,_cache: any) => {
  return (_unref(scheduleStore).schedule)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(scheduleStore).schedule.dayNumber), 1),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(scheduleStore).schedule.monthNumber), 1),
              _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(scheduleStore).schedule.weekDay), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(configStore).config.schedule_segments, (segment) => {
            return (_openBlock(), _createElementBlock("div", {
              key: segment.id,
              class: "schedule__card"
            }, [
              _createElementVNode("div", _hoisted_9, _toDisplayString(segment.title), 1),
              _createElementVNode("div", _hoisted_10, _toDisplayString(segment.start_time) + " - " + _toDisplayString(segment.end_time), 1),
              _createElementVNode("div", _hoisted_11, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getOffers(segment.start_time, segment.end_time), (offer) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: offer.startDate + offer.startTime,
                    class: _normalizeClass(["schedule__time", {
              'not-active': !offer.availableSlots || !offer.isActive,
            }]),
                    onClick: ($event: any) => (clickTime(offer))
                  }, _toDisplayString(offer.startTime) + " - " + _toDisplayString(offer.endTime), 11, _hoisted_12))
                }), 128))
              ])
            ]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})