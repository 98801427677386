import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = ["innerHTML"]

import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TariffPopup',
  props: {
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
},
  emits: ['hide'],
  setup(__props, { emit: __emit }) {

const emits = __emit;
const hide = () => {
  emits('hide');
};

const props = __props;

const showPopup = ref(true);

return (_ctx: any,_cache: any) => {
  const _component_prime_dialog = _resolveComponent("prime-dialog")!

  return (_openBlock(), _createBlock(_component_prime_dialog, {
    visible: showPopup.value,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((showPopup).value = $event)),
    class: "tariff-popup",
    modal: "",
    header: __props.title,
    style: { width: '80%' },
    onHide: _cache[1] || (_cache[1] = ($event: any) => (hide()))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "row",
          innerHTML: __props.description
        }, null, 8, _hoisted_2)
      ])
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}
}

})