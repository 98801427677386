import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex align-items-center gap-3 mb-3" }
const _hoisted_2 = {
  key: 0,
  class: "reset-password__error gap-3 mb-3"
}
const _hoisted_3 = { class: "flex justify-content-center gap-2 mb-3" }
const _hoisted_4 = { class: "flex align-items-center gap-3 mb-3" }
const _hoisted_5 = { class: "flex align-items-center gap-3 mb-3" }
const _hoisted_6 = { class: "flex align-items-center gap-3 mb-3" }
const _hoisted_7 = {
  key: 0,
  class: "reset-password__error gap-3 mb-3"
}
const _hoisted_8 = { class: "flex justify-content-center gap-2 mb-3" }

import { ref, reactive } from 'vue';
import FitnesService from '@/services/FitnesService';
import IConfirmPayload from '@/types/IConfirmPayload';
import IChangePassPayload from '@/types/IChangePassPayload';
import IError from '@/types/IError';
import { useAuthStore } from '@/store/auth';
import { useConfigStore } from '@/store/config';


export default /*@__PURE__*/_defineComponent({
  __name: 'ResetPopup',
  emits: ['hide', 'reopen'],
  setup(__props, { emit: __emit }) {

const authStore = useAuthStore();

const emits = __emit;

const showElements = ref({
  view1: true,
  view2: false,
});

const hide = () => {
  if (!showElements.value.view2) {
    emits('hide');
  }
};

const phone = ref();
const confirmationCode = ref();
// const userToken = ref();
const errorObj = reactive<IError>({
  code: 0,
  type: '',
  message: '',
});

// const retryTimer = (second = 5) => {
//   retryTime.value = second;
//   const intervalId = setInterval(() => {
//     retryTime.value -= 1;
//     if (retryTime.value === 0) clearInterval(intervalId);
//   }, 1000);
// };

const sendCode = async () => {
  const localPhone = phone.value.replace(/[()\-\+\s]/g, '');
  const payload: IConfirmPayload = {
    phone: localPhone,
    auth_type: 'sms',
  };
  const configStore = useConfigStore();
  // eslint-disable-next-line
  const res = await FitnesService.confirmPhone(configStore.integration_code, payload);
  const { data, result } = res.data;
};

const clearError = () => {
  errorObj.code = 0;
  errorObj.type = '';
  errorObj.message = '';
};

const confirmPhone = async () => {
  const localPhone = phone.value.replace(/[()\-\+\s]/g, '');
  const payload: IConfirmPayload = {
    phone: localPhone,
    auth_type: 'sms',
    confirmation_code: confirmationCode.value,
  };
  const configStore = useConfigStore();
  // eslint-disable-next-line
  const res = await FitnesService.confirmPhone(configStore.integration_code, payload);
  const { data, result, error, error_message } = res.data;

  if (result) {
    clearError();
    authStore.setUserToken(data.pass_token);
  } else {
    errorObj.code = error;
    errorObj.type = 'code';
    errorObj.message = error_message;
    return false;
  }
  return true;
};

const password = ref('');
const confirmPassword = ref('');

const changePassword = async () => {
  const localPhone = phone.value.replace(/[()\-\+\s]/g, '');
  const payload: IChangePassPayload = {
    phone: localPhone,
    pass_token: authStore.userToken,
    password: password.value,
  };
  const configStore = useConfigStore();
  // eslint-disable-next-line
  const res = await FitnesService.changePassword(configStore.integration_code, payload);
  const { data, result } = res.data;
  if (result && data.user_token) {
    console.log('ut!', data.user_token);
    authStore.setUserToken(data.user_token);
  }
};

const clickSendSMS = async () => {
  if (
    !phone.value ||
    phone.value.replace(/[()\-\+\s]/g, '').length < 11
  ) {
    errorObj.code = 1;
    errorObj.message = 'Проверьте правильность ввода';
    errorObj.type = 'phone number';
    return;
  }
  clearError();
  sendCode();
  showElements.value.view1 = false;
  showElements.value.view2 = true;
};

const clickChangePassword = async () => {
  if (password.value !== confirmPassword.value) {
    errorObj.code = 2;
    errorObj.type = 'password';
    errorObj.message = 'Пароль и подтверждение пароля не совпадают';
    return;
  }

  if (!password.value || password.value.length < 8) {
    errorObj.code = 3;
    errorObj.type = 'password';
    errorObj.message = 'Пароль должен содержать минимум 8 символов';
    return;
  }
  if (!confirmationCode.value || confirmationCode.value.length < 4) {
    errorObj.code = 4;
    errorObj.type = 'code';
    errorObj.message = 'Не верный код подтверждения';
    return;
  }
  const statusConfirmPhone = await confirmPhone();
  if (!statusConfirmPhone) return;
  await changePassword();
  showElements.value.view2 = false;
};

const checkPassword = () => {
  if (
    password.value === confirmPassword.value &&
    errorObj.code === 2
  ) {
    clearError();
  }
};

return (_ctx: any,_cache: any) => {
  const _component_InputMask = _resolveComponent("InputMask")!
  const _component_prime_dialog = _resolveComponent("prime-dialog")!
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_prime_dialog, {
      visible: showElements.value.view1,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((showElements.value.view1) = $event)),
      class: "reset-password",
      modal: "",
      header: "Сброс пароля",
      style: { width: '25rem' },
      onHide: _cache[3] || (_cache[3] = ($event: any) => (hide()))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[10] || (_cache[10] = _createElementVNode("label", {
            for: "phone",
            class: "font-semibold w-6rem"
          }, "Телефон", -1)),
          _createVNode(_component_InputMask, {
            id: "phone",
            modelValue: phone.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((phone).value = $event)),
            mask: "+9(999)999-99-99",
            class: "flex-auto",
            autocomplete: "off"
          }, null, 8, ["modelValue"])
        ]),
        (errorObj.message && errorObj.code == 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("span", null, _toDisplayString(errorObj.message), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            class: "fit-btn-2",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (clickSendSMS()))
          }, " Отправить смс ")
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_prime_dialog, {
      visible: showElements.value.view2,
      "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => ((showElements.value.view2) = $event)),
      class: "reset-password",
      modal: "",
      header: "Сброс пароля",
      style: { width: '25rem' },
      onHide: _cache[9] || (_cache[9] = ($event: any) => (hide()))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_InputText, {
            id: "password",
            modelValue: password.value,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((password).value = $event)),
            input: checkPassword(),
            type: "password",
            placeholder: "Пароль",
            class: "flex-auto",
            autocomplete: "off"
          }, null, 8, ["modelValue", "input"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_InputText, {
            id: "password",
            modelValue: confirmPassword.value,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((confirmPassword).value = $event)),
            type: "password",
            input: checkPassword(),
            class: "flex-auto",
            placeholder: "Подтверждение пароля",
            autocomplete: "off"
          }, null, 8, ["modelValue", "input"])
        ]),
        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "reset-password__text gap-3 mb-3" }, " Сообщение с кодом подтверждения отправлено по СМС ", -1)),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_InputText, {
            id: "confirm-code",
            modelValue: confirmationCode.value,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((confirmationCode).value = $event)),
            class: "flex-auto",
            placeholder: "Код подтверждения",
            autocomplete: "off"
          }, null, 8, ["modelValue"])
        ]),
        (errorObj.message && errorObj.code !== 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("span", null, _toDisplayString(errorObj.message), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("button", {
            class: "fit-btn-2",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (clickChangePassword()))
          }, " Изменить пароль ")
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})