import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fit-container" }
const _hoisted_2 = { class: "calendar-wrap" }
const _hoisted_3 = { class: "tariff-box-wrap" }
const _hoisted_4 = { class: "selected-wrap" }

import { ref, onBeforeMount } from 'vue';
import TheCalendar from './components/TheCalendar.vue';
import ScheduleBox from './components/ScheduleBox.vue';
import TariffsBox from './components/TariffsBox.vue';
import SelectedTariffs from './components/SelectedTariffs.vue';
import CartBox from './components/CartBox.vue';
import { useConfigStore } from './store/config';

interface IConfig {
  title: string
}

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

// import Offer from '@/types/IOffer';
// import { useClassesStore } from '@/store/classes';
const configStore = useConfigStore();

// const $scriptElement = document.querySelector('[integration]');
const clientConfig = ref<IConfig>({
  title: '',
})

onBeforeMount(async ()=>{
  const $scriptElement: HTMLElement | null = document.querySelector("#app");
  if ($scriptElement){
    const integrationCode = $scriptElement.getAttribute('integration-code');
    if(integrationCode){
      clientConfig.value = await configStore.getConfig(integrationCode);
    }
    // clientConfig = config.find((el) => {
    //   return el.clientCode === clientCode;
    // },
}
})



// const classesStore = useClassesStore();

// const showElements = ref({
//   showDayBtn: true,
//   showCalendar: true,
//   showTariffBtn: false,
//   showTariffs: false,
//   showSelectedTariffs: false,
//   showPayBtn: false,
//   showLogin: false,
//   showPassReset: false,
//   showRegister: false,
// });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(clientConfig.value.title), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(TheCalendar),
      _createVNode(ScheduleBox)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(TariffsBox)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(SelectedTariffs),
      _createVNode(CartBox)
    ])
  ]))
}
}

})