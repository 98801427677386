import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "cart-box"
}
const _hoisted_2 = { class: "cart-box__title" }
const _hoisted_3 = { class: "cart-box__line" }
const _hoisted_4 = { class: "cart-box__line" }
const _hoisted_5 = { class: "cart-box__line" }
const _hoisted_6 = {
  key: 0,
  class: "cart-box__discount"
}
const _hoisted_7 = { class: "cart-box__discount-amount" }
const _hoisted_8 = { class: "cart-box__price" }
const _hoisted_9 = { class: "cart-box__promocode" }
const _hoisted_10 = { class: "field" }
const _hoisted_11 = { class: "cart-box__footer" }

import { ref, computed } from 'vue';
import { useBookingStore } from '@/store/booking';
import { useAuthStore } from '@/store/auth';
import IOrderGroup from '@/types/IOrderGroup';
import IOrder from '@/types/IOrder';
import LoginPopup from './LoginPopup.vue';
import RegisterPopup from './RegisterPopup.vue';
import ResetPopup from './ResetPopup.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CartBox',
  setup(__props) {

const authStore = useAuthStore();

const showPopups = ref({
  loginPopup: false,
  registerPopup: false,
  forgotPassPopup: false,
});

const openRegisterPopup = () => {
  showPopups.value.loginPopup = false;
  showPopups.value.registerPopup = true;
};

const openForgotPassPopup = () => {
  showPopups.value.loginPopup = false;
  showPopups.value.forgotPassPopup = true;
};

const totalAmount = (orderGroups: IOrderGroup[]) => {
  let amount = 0;
  orderGroups.forEach((orderGroup: IOrderGroup) => {
    orderGroup.orders.forEach((order: IOrder) => {
      amount += order.price;
    });
  });
  return amount - bookingStore.total_discount;
};

const countQuestString = (order: IOrder) => {
  const count = order.adults + order.children;
  let result;
  if (count % 10 === 1 && count % 100 !== 11) {
    result = `${count} гость`;
  } else if (
    count % 10 >= 2 &&
    count % 10 <= 4 &&
    (count % 100 < 10 || count % 100 >= 20)
  ) {
    result = `${count} гостя`;
  } else {
    result = `${count} гостей`;
  }
  return result;
};

const clickApplyPromocode = async () => {
  await bookingStore.updateCost();
};

const clickBuyButton = async() => {
  if(!authStore.userToken){
    const userToken = localStorage.getItem('user_token');
    if(userToken){
      authStore.setUserToken(userToken);
    } else {
      showPopups.value.loginPopup = true;
    }
  }
  await authStore.waitForSetUserToken();
  console.log('Токен установлен');

  const payment_info = await bookingStore.payment();

  console.log('payment_info', payment_info);
  dataLayer.push(payment_info);
}

const bookingStore = useBookingStore();

return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (
      _unref(bookingStore).orderGroups.length
    )
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(bookingStore).orderGroups, (orderGroup) => {
            return (_openBlock(), _createElementBlock("div", {
              key: orderGroup.dayNumber + orderGroup.monthNumber,
              class: "cart-box__group"
            }, [
              _createElementVNode("div", _hoisted_2, [
                _cache[6] || (_cache[6] = _createElementVNode("span", null, "Дата посещения: ", -1)),
                _createTextVNode(_toDisplayString(orderGroup.dateString), 1)
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(orderGroup.orders, (order) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: order.date + order.time,
                  class: "cart-box__session"
                }, [
                  _createElementVNode("div", _hoisted_3, [
                    _cache[7] || (_cache[7] = _createElementVNode("span", null, "Сеанс: ", -1)),
                    _createTextVNode(_toDisplayString(order.time), 1)
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _cache[8] || (_cache[8] = _createElementVNode("span", null, "Гостей: ", -1)),
                    _createTextVNode(_toDisplayString(countQuestString(order)), 1)
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _cache[9] || (_cache[9] = _createElementVNode("span", null, "К оплате: ", -1)),
                    _createTextVNode(_toDisplayString(order.price) + " ₽ ", 1)
                  ])
                ]))
              }), 128))
            ]))
          }), 128)),
          (_unref(bookingStore).total_discount)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _cache[10] || (_cache[10] = _createElementVNode("span", { class: "font-semibold" }, "Применена скидка: ", -1)),
                _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(bookingStore).total_discount) + " ₽ ", 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_8, [
            _cache[11] || (_cache[11] = _createElementVNode("span", null, "Итого: ", -1)),
            _createTextVNode(_toDisplayString(totalAmount(_unref(bookingStore).orderGroups)) + " ₽ ", 1)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _cache[12] || (_cache[12] = _createElementVNode("label", {
                for: "account_id",
                class: "font-semibold"
              }, "Промокод:", -1)),
              _createVNode(_component_InputText, {
                id: "account_id",
                modelValue: _unref(bookingStore).promocode,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(bookingStore).promocode) = $event)),
                modelModifiers: { trim: true },
                required: "true",
                autofocus: "",
                class: "w-full mb-3"
              }, null, 8, ["modelValue"]),
              (_unref(bookingStore).promocode && _unref(bookingStore).promocode.length > 3)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "cart-box__btn",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (clickApplyPromocode()))
                  }, " Применить промокод "))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", {
              class: "cart-box__btn",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (clickBuyButton()))
            }, " Оплатить ")
          ])
        ]))
      : _createCommentVNode("", true),
    (showPopups.value.loginPopup)
      ? (_openBlock(), _createBlock(LoginPopup, {
          key: 1,
          onHide: _cache[3] || (_cache[3] = ($event: any) => (showPopups.value.loginPopup = false)),
          onOpenRegisterPopup: openRegisterPopup,
          onOpenForgotPassPopup: openForgotPassPopup
        }))
      : _createCommentVNode("", true),
    (showPopups.value.registerPopup)
      ? (_openBlock(), _createBlock(RegisterPopup, {
          key: 2,
          onHide: _cache[4] || (_cache[4] = ($event: any) => (showPopups.value.registerPopup = false))
        }))
      : _createCommentVNode("", true),
    (showPopups.value.forgotPassPopup)
      ? (_openBlock(), _createBlock(ResetPopup, {
          key: 3,
          onHide: _cache[5] || (_cache[5] = ($event: any) => (showPopups.value.forgotPassPopup = false))
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})