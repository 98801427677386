import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "children-popup__consent"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 2,
  class: "children-popup__error"
}

import { ref } from 'vue';
import { useBookingStore } from '@/store/booking';


export default /*@__PURE__*/_defineComponent({
  __name: 'AddChildrenPopup',
  props: {
  uid: {
    type: String,
    required: true,
  },
},
  emits: ['hide'],
  setup(__props, { emit: __emit }) {

const bookingStore = useBookingStore();

const props = __props;

const emits = __emit;
const hide = () => {
  emits('hide');
};

const showPopup = ref(true);

const isConsent = ref(false);
const isError = ref(false);

const addChildren = (uid: string) => {
  const status = bookingStore.addChildren(uid);
  if (!status) {
    isError.value = true;
  } else {
    hide();
  }
};

return (_ctx: any,_cache: any) => {
  const _component_input_checkbox = _resolveComponent("input-checkbox")!
  const _component_prime_dialog = _resolveComponent("prime-dialog")!

  return (_openBlock(), _createBlock(_component_prime_dialog, {
    visible: showPopup.value,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((showPopup).value = $event)),
    class: "children-popup",
    modal: "",
    header: "Добавление детского тарифа",
    onHide: _cache[3] || (_cache[3] = ($event: any) => (hide()))
  }, {
    default: _withCtx(() => [
      (!isError.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_input_checkbox, {
              modelValue: isConsent.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((isConsent).value = $event)),
              class: "children-popup__checkbox",
              binary: true
            }, null, 8, ["modelValue"]),
            _cache[4] || (_cache[4] = _createElementVNode("span", null, "Сопровождающий является законным представителем, или имеет письменное согласие от законного представителя на посещение ребенка.", -1))
          ]))
        : _createCommentVNode("", true),
      (!isError.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Оплата за посещение ребенка (6-14 лет) будет на месте с представлением документа подтверждающего возраст. "))
        : _createCommentVNode("", true),
      (isError.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, " На один \"взрослый тариф\" возможно добавить не более двух детских тарифов. "))
        : _createCommentVNode("", true),
      (isConsent.value && !isError.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 3,
            class: "children-popup__button",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (addChildren(__props.uid)))
          }, " Добавить детский тариф "))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})