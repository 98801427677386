/* eslint-disable max-len */
const agreement = `
<h3>СОГЛАСИЕ посетителя сайта на обработку персональных данных</h3>
<p>Настоящим я, во исполнение требований Федерального закона от 27.07.2006 г. № 152 - ФЗ «О персональных данных» (с изменениями и дополнениями) свободно, своей волей и своем интересе, а также подтверждая свою дееспособность, даю свое согласие Обществу с ограниченной ответственностью «Грин Флоу Лахта Парк» (ИНН 7814786724), место нахождения: Россия, г.Санкт - Петербург, ул.Новая, д. 51, корп. 79(далее – Общество), сайт Общества: https:// greenflowlakhtapark.ru на обработку своих персональных данных со следующими условиям:</p>
<p>1. Данное Согласие дается на обработку персональных данных, как без использования средств автоматизации, так и с их использованием.Согласие дается на обработку следующих моих персональных данных: фамилия, имя, отчество;  номер телефона(домашний / сотовый); электронная почта; пользовательские данные(сведения о местоположении, тип и версия ОС, тип и версия Браузера, тип устройства и разрешение его экрана; источник, откуда пришел на сайт пользователь; с какого сайта или по какой рекламе; язык ОС и Браузера; какие страницы открывает и на какие кнопки  нажимает пользователь; ip - адрес); данные обо мне, которые станут известны в ходе исполнения договоров(в случае заключения договора между мной и  Обществом), а также иная общедоступная  информация обо мне.</p>

<p>2. Персональные данные не являются общедоступными.</p>

<p>3. Цель обработки персональных данных: обработка входящих запросов физических лиц с целью оказания консультирования по различным вопросам, относящимся к сфере предпринимательской деятельности Общества; аналитики действий физического лица на вебсайте и функционирования веб - сайта.</p>

<p>4. Основанием для обработки персональных данных является статья 24 Конституции Российской Федерации; статья 6 Федерального закона № 152 - ФЗ «О персональных данных»; настоящее Согласие посетителя сайта на обработку персональных данных.</p>

<p>5. В ходе обработки с персональными данными будут совершены следующие действия: сбор, запись, систематизация, накопление, хранение, уточнение(обновление, изменение), извлечение, использование, передача(распространение, предоставление, доступ), блокирование, удаление, уничтожение.</p>

<p>6. Настоящим я уведомлен Обществом, что предполагаемыми пользователями персональных данных являются работники Общества, а также лица, привлеченные Обществом на условиях гражданско - правового договора.</p>

<p>7. Я ознакомлен(а), что:</p>

<p>7.1.настоящее Согласие на обработку моих персональных данных является бессрочным и может быть отозвано посредством  направления мною в адрес Общества(197229, г.Санкт - Петербург ул.Новая д. 51 к. 79 стр. 1 этаж 1 офис 1) письменного заявления.Датой отзыва считается день, следующий за днем вручения Обществу письменного заявления об отзыве Согласия пользователя сайта на обработку персональных данных;</p>

<p>7.2.Имею право на доступ к моим персональным данным, требовать уточнения(обновление, изменение) моих персональных данных, а также удаления и уничтожения моих персональных данных в случае их обработки Обществом, нарушающих мои законные права и интересы, законодательство Российской Федерации.</p>

<p>7.3.В случае отзыва Согласия на обработку персональных данных Общество вправе  продолжить обработку персональных данных без моего согласия при наличии оснований, указанных в пунктах 2.11 части 1 статьи 6, части 2 статьи 10 и части 2 статьи 11 Федерального закона №152 - ФЗ «О персональных данных» от 27.07.2006 г.</p>

<p>8. Я обязуюсь в случае изменения моих персональных данных, сведений обо мне незамедлительно сообщить Обществу, с предоставлением подтверждающих документов.</p>

<p>9. Настоящим Согласием я подтверждаю, что являюсь субъектом предоставляемых персональных данных, а также подтверждаю достоверность предоставляемых данных.</p>

<p>10. Настоящее Согласие действует все время до момента прекращения обработки персональных данных, согласно п. 7.1 Согласия."</p>`;

export default agreement;
