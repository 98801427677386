import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, unref as _unref, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex align-items-center gap-3 mb-3" }
const _hoisted_2 = { class: "flex align-items-center gap-3 mb-3" }
const _hoisted_3 = { class: "flex align-items-center gap-3" }
const _hoisted_4 = {
  key: 0,
  class: "register__error gap-3 mt-1 mb-3"
}
const _hoisted_5 = { class: "mb-3 mt-3" }
const _hoisted_6 = { class: "register__text" }
const _hoisted_7 = { class: "flex justify-content-center gap-2 mb-3" }
const _hoisted_8 = { class: "flex align-items-center gap-3" }
const _hoisted_9 = {
  key: 0,
  class: "register__error gap-3 mt-1"
}
const _hoisted_10 = { class: "flex justify-content-center gap-2 mt-3 mb-3" }
const _hoisted_11 = { class: "flex align-items-center gap-3" }
const _hoisted_12 = {
  key: 0,
  class: "register__error"
}
const _hoisted_13 = { class: "flex align-items-center gap-3 mt-3" }
const _hoisted_14 = {
  key: 1,
  class: "register__error"
}
const _hoisted_15 = { class: "flex align-items-center gap-3 mt-3" }
const _hoisted_16 = {
  key: 2,
  class: "register__error"
}
const _hoisted_17 = { class: "flex align-items-center gap-3 mt-3" }
const _hoisted_18 = {
  key: 3,
  class: "register__error"
}
const _hoisted_19 = { class: "flex align-items-center gap-3 mt-3" }
const _hoisted_20 = {
  key: 4,
  class: "register__error"
}
const _hoisted_21 = { class: "flex justify-content-center gap-2 mt-3 mb-3" }
const _hoisted_22 = ["innerHTML"]

import { ref, reactive, computed } from 'vue';
import IError from '@/types/IError';
import IConfirmPayload from '@/types/IConfirmPayload';
import IChangePassPayload from '@/types/IChangePassPayload';
import IRegAndAuthPayload from '@/types/IRegAndAuthPayload';
import agreement from '@/data/agreement';
import FitnesService from '@/services/FitnesService';
import { useAuthStore } from '@/store/auth';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import { useConfigStore } from '@/store/config';


export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterPopup',
  emits: ['hide'],
  setup(__props, { emit: __emit }) {

const authStore = useAuthStore();
const emits = __emit;

const errorObj = reactive<IError>({
  code: 0,
  type: '',
  message: '',
});

const phone = ref();
const password = ref();
const confirmPassword = ref();
const acceptedAgreement = ref();
const confirmationCode = ref();
// const userToken = ref();

const firstName = ref();
const middleName = ref();
const lastName = ref();
const email = ref();
// const gender = ref();
const birthday = ref();

const showElements = ref({
  view1: true,
  view2: false,
  view3: false,
  view4: false,
  counter: false,
});

const clearError = () => {
  errorObj.code = 0;
  errorObj.type = '';
  errorObj.message = '';
};

const hide = () => {
  if (!showElements.value.view2 && !showElements.value.view3) {
    emits('hide');
  }
};

const sendCode = async () => {
  const localPhone = phone.value.replace(/[()\-\+\s]/g, '');
  const payload: IConfirmPayload = {
    phone: localPhone,
    auth_type: 'sms',
  };

  const configStore = useConfigStore();
  // eslint-disable-next-line
  const res = await FitnesService.confirmPhone(configStore.integration_code, payload);
  const { data, result } = res.data;
  console.log('data', data);
  console.log('result', result);
};

const confirmPhone = async () => {
  const localPhone = phone.value.replace(/[()\-\+\s]/g, '');
  const payload: IConfirmPayload = {
    phone: localPhone,
    auth_type: 'sms',
    confirmation_code: confirmationCode.value,
  };
  const configStore = useConfigStore();
  // eslint-disable-next-line
  const res = await FitnesService.confirmPhone(configStore.integration_code, payload);
  const { data, result, error, error_message } = res.data;
  console.log('data', data);
  console.log('result', result);

  if (result) {
    clearError();
    authStore.setUserToken(data.pass_token);
  } else {
    errorObj.code = error;
    errorObj.type = 'code';
    errorObj.message = error_message;
    return false;
  }
  return true;
};

const auth = async () => {
  const localPhone = phone.value.replace(/[()\-\+\s]/g, '');
  const payload: IRegAndAuthPayload = {
    phone: localPhone,
    pass_token: authStore.userToken,
    password: password.value,
    last_name: lastName.value,
    name: firstName.value,
    second_name: middleName.value,
    email: email.value,
    birthday: birthday.value,
  };
  const configStore = useConfigStore();
  // eslint-disable-next-line
  const res = await FitnesService.regAndAuth(configStore.integration_code, payload);
  const { data, result, error, error_message } = res.data;
  console.log('data', data);
  console.log('result', result);
  if (result) {
    clearError();
    // VueCookies.set('userToken', data.pass_token);
    authStore.setUserToken(data.pass_token);
  } else {
    errorObj.code = error;
    errorObj.type = 'code';
    errorObj.message = error_message;
    return false;
  }
  return true;
};

const changePassword = async () => {
  const localPhone = phone.value.replace(/[()\-\+\s]/g, '');
  const payload: IChangePassPayload = {
    phone: localPhone,
    pass_token: authStore.userToken,
    password: password.value,
  };
  const configStore = useConfigStore();
  // eslint-disable-next-line
  const res = await FitnesService.changePassword(configStore.integration_code, payload);
  const { data, result } = res.data;
  if (result && data.pass_token) {
    return true;
  }
  return false;
};

const clickRegister = async () => {
  if (
    !phone.value ||
    phone.value.replace(/[()\-\+\s]/g, '').length < 11
  ) {
    errorObj.code = 1;
    errorObj.message = 'Проверьте правильность ввода телефона';
    errorObj.type = 'phone number';
    return;
  }

  if (password.value !== confirmPassword.value) {
    errorObj.code = 2;
    errorObj.type = 'password';
    errorObj.message = 'Пароль и подтверждение пароля не совпадают';
    return;
  }

  if (!password.value || password.value.length < 8) {
    errorObj.code = 3;
    errorObj.type = 'password';
    errorObj.message = 'Пароль должен содержать минимум 8 символов';
    return;
  }

  if (!acceptedAgreement.value) {
    errorObj.code = 4;
    errorObj.type = 'accepted agreement';
    errorObj.message =
      'Вы не приняли соглашение о персональных данных';
    return;
  }
  clearError();
  sendCode();
  showElements.value.view1 = false;
  showElements.value.view2 = true;
};
// const clickSendSMS = () => {
//   showElements.value.counter = true;
// };
const clickConfirm = async () => {
  if (!confirmationCode.value) {
    errorObj.code = 5;
    errorObj.type = 'confirm code';
    errorObj.message = 'Введите код подтверждения';
    return;
  }
  const confirmStatus = await confirmPhone();
  if (confirmStatus) {
    clearError();
    changePassword();
  } else {
    return;
  }
  // const registerStatus = await auth();
  // if (registerStatus) {
  //   showElements.value.view1 = false;
  //   showElements.value.view2 = false;
  // }
  showElements.value.view2 = false;
  showElements.value.view3 = true;
};

const showTermsOfService = () => {
  showElements.value.view1 = false;
  showElements.value.view4 = true;
};

const checkPassword = () => {
  if (
    password.value === confirmPassword.value &&
    errorObj.code === 2
  ) {
    clearError();
  }
};

const rules = computed(() => ({
  lastName: {
    required: helpers.withMessage('Поле обязательное', required),
  },
  firstName: {
    required: helpers.withMessage('Поле обязательное', required),
  },
  middleName: {
    required: helpers.withMessage('Поле обязательное', required),
  },
  email: {
    required: helpers.withMessage('Поле обязательное', required),
  },
  birthday: {
    required: helpers.withMessage('Поле обязательное', required),
  },
}));

const v$ = useVuelidate(rules, {
  lastName,
  firstName,
  middleName,
  email,
  birthday,
});

const clickSendAbout = async () => {
  v$.value.$touch();
  if (v$.value.$error) return;

  const registerStatus = await auth();
  showElements.value.view3 = false;
};

return (_ctx: any,_cache: any) => {
  const _component_InputMask = _resolveComponent("InputMask")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_input_checkbox = _resolveComponent("input-checkbox")!
  const _component_prime_dialog = _resolveComponent("prime-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_prime_dialog, {
      visible: showElements.value.view1,
      "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => ((showElements.value.view1) = $event)),
      class: "register",
      modal: "",
      header: "Регистрация",
      style: { width: '25rem' },
      onHide: _cache[9] || (_cache[9] = ($event: any) => (hide()))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_InputMask, {
            id: "phone",
            modelValue: phone.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((phone).value = $event)),
            placeholder: "Телефон",
            mask: "+9(999)999-99-99",
            class: "flex-auto",
            autocomplete: "off"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_InputText, {
            id: "password",
            modelValue: password.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
            type: "password",
            placeholder: "Пароль",
            class: "flex-auto",
            autocomplete: "off",
            onInput: _cache[2] || (_cache[2] = ($event: any) => (checkPassword()))
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_InputText, {
            id: "password",
            modelValue: confirmPassword.value,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((confirmPassword).value = $event)),
            type: "password",
            class: "flex-auto",
            placeholder: "Подтверждение пароля",
            autocomplete: "off",
            onInput: _cache[4] || (_cache[4] = ($event: any) => (checkPassword()))
          }, null, 8, ["modelValue"])
        ]),
        (errorObj.message)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("span", null, _toDisplayString(errorObj.message), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_input_checkbox, {
            modelValue: acceptedAgreement.value,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((acceptedAgreement).value = $event)),
            binary: true
          }, null, 8, ["modelValue"]),
          _createElementVNode("span", _hoisted_6, [
            _cache[24] || (_cache[24] = _createTextVNode(" Я ознакомлен с правилами и согласен на ")),
            _createElementVNode("a", {
              href: "#",
              class: "register__link",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (showTermsOfService()))
            }, "обработку персональных данных")
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            class: "fit-btn-2",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (clickRegister()))
          }, " Зарегистрироваться ")
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_prime_dialog, {
      visible: showElements.value.view2,
      "onUpdate:visible": _cache[12] || (_cache[12] = ($event: any) => ((showElements.value.view2) = $event)),
      class: "register",
      modal: "",
      header: "Регистрация",
      style: { width: '25rem' },
      onHide: _cache[13] || (_cache[13] = ($event: any) => (hide()))
    }, {
      default: _withCtx(() => [
        _cache[25] || (_cache[25] = _createElementVNode("div", { class: "register__text-whatsapp gap-3 mb-3" }, " Сообщение с кодом подтверждения отправлено по СМС ", -1)),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_InputText, {
            id: "confirm-code",
            modelValue: confirmationCode.value,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((confirmationCode).value = $event)),
            class: "flex-auto",
            placeholder: "Код подтверждения",
            autocomplete: "off"
          }, null, 8, ["modelValue"])
        ]),
        (errorObj.message)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("span", null, _toDisplayString(errorObj.message), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("button", {
            class: "fit-btn-2",
            onClick: _cache[11] || (_cache[11] = ($event: any) => (clickConfirm()))
          }, " Подтвердить ")
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_prime_dialog, {
      visible: showElements.value.view3,
      "onUpdate:visible": _cache[20] || (_cache[20] = ($event: any) => ((showElements.value.view3) = $event)),
      class: "register",
      modal: "",
      header: "Расскажите о себе",
      style: { width: '25rem' },
      onHide: _cache[21] || (_cache[21] = ($event: any) => (hide()))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_InputText, {
            id: "confirm-code",
            modelValue: _unref(v$).lastName.$model,
            "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_unref(v$).lastName.$model) = $event)),
            class: "flex-auto",
            placeholder: "Фамилия",
            autocomplete: "off"
          }, null, 8, ["modelValue"])
        ]),
        (_unref(v$).lastName.$errors.length)
          ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_unref(v$).lastName.$errors[0].$message), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_InputText, {
            id: "confirm-code",
            modelValue: _unref(v$).firstName.$model,
            "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_unref(v$).firstName.$model) = $event)),
            class: "flex-auto",
            placeholder: "Имя",
            autocomplete: "off"
          }, null, 8, ["modelValue"])
        ]),
        (_unref(v$).firstName.$errors.length)
          ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_unref(v$).firstName.$errors[0].$message), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_InputText, {
            id: "confirm-code",
            modelValue: _unref(v$).middleName.$model,
            "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_unref(v$).middleName.$model) = $event)),
            class: "flex-auto",
            placeholder: "Отчество",
            autocomplete: "off"
          }, null, 8, ["modelValue"])
        ]),
        (_unref(v$).middleName.$errors.length)
          ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_unref(v$).middleName.$errors[0].$message), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_17, [
          _createVNode(_component_InputText, {
            id: "confirm-code",
            modelValue: _unref(v$).email.$model,
            "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_unref(v$).email.$model) = $event)),
            class: "flex-auto",
            placeholder: "E-mail",
            autocomplete: "off"
          }, null, 8, ["modelValue"])
        ]),
        (_unref(v$).email.$errors.length)
          ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_unref(v$).email.$errors[0].$message), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_19, [
          _createVNode(_component_InputText, {
            id: "confirm-code",
            modelValue: _unref(v$).birthday.$model,
            "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_unref(v$).birthday.$model) = $event)),
            placeholder: "Дата рождения",
            class: "flex-auto",
            autocomplete: "off"
          }, null, 8, ["modelValue"])
        ]),
        (_unref(v$).birthday.$errors.length)
          ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_unref(v$).birthday.$errors[0].$message), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("button", {
            class: "fit-btn-2",
            onClick: _cache[19] || (_cache[19] = ($event: any) => (clickSendAbout()))
          }, " Отправить ")
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_prime_dialog, {
      visible: showElements.value.view4,
      "onUpdate:visible": _cache[22] || (_cache[22] = ($event: any) => ((showElements.value.view4) = $event)),
      class: "register",
      modal: "",
      style: { width: '25rem' },
      onHide: _cache[23] || (_cache[23] = ($event: any) => (showElements.value.view1 = true))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", { innerHTML: _unref(agreement) }, null, 8, _hoisted_22)
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})