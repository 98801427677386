import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/plus.png'


const _hoisted_1 = {
  key: 0,
  class: "tariffs"
}
const _hoisted_2 = { class: "tariffs__right" }
const _hoisted_3 = { class: "tariffs__card-main" }
const _hoisted_4 = { class: "tariffs__card-title" }
const _hoisted_5 = { class: "tariffs__card-desc" }
const _hoisted_6 = { class: "tariffs__card-footer" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onClick"]

import { ref } from 'vue';
import ITariff from '@/types/ITariff';
import { useTariffsStore } from '@/store/tariffs';
import { useBookingStore } from '@/store/booking';
import TariffPopup from './TariffPopup.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TariffsBox',
  setup(__props) {

const tariffsStore = useTariffsStore();
const bookingStore = useBookingStore();
tariffsStore.fetchAllTariffs();

const clickAddButton = (tariff: ITariff) => {
  bookingStore.addOrder(tariff, tariffsStore.lastDateTime);
  console.log('add tariff', tariff);
};

const showTariffPopup = ref(false);
const popupTitle = ref('');
const popupDescription = ref('');
const openAboutPopup = (tariff: ITariff) => {
  showTariffPopup.value = true;
  popupTitle.value = tariff.title;
  popupDescription.value = tariff.description;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(tariffsStore).availableTariffs.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"tariffs__left\" data-v-09161cc3><div class=\"tariffs__header\" data-v-09161cc3><div class=\"tariffs__desc-title\" data-v-09161cc3>Тарифы</div><div class=\"tariffs__desc-text\" data-v-09161cc3> Текст описание Это место для всестороннего расслабления и восстановления сил, где представлены различные виды бассейнов, бань, соляная пещера, аромапарные и дополнительных устройств, таких как душ </div></div><div class=\"tariffs__footer\" data-v-09161cc3></div></div>", 1)),
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tariffsStore).availableTariffs, (tariff) => {
              return (_openBlock(), _createElementBlock("div", {
                key: tariff.id,
                class: "tariffs__card"
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, _toDisplayString(tariff.title), 1),
                  _createElementVNode("div", _hoisted_5, _toDisplayString(tariff.availableTime), 1)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", {
                    class: "tariffs__add-button",
                    onClick: ($event: any) => (clickAddButton(tariff))
                  }, _cache[1] || (_cache[1] = [
                    _createElementVNode("img", {
                      src: _imports_0,
                      alt: "add button"
                    }, null, -1)
                  ]), 8, _hoisted_7),
                  _createElementVNode("div", {
                    class: "tariffs__info",
                    onClick: ($event: any) => (openAboutPopup(tariff))
                  }, " Подробней ", 8, _hoisted_8)
                ])
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (showTariffPopup.value)
      ? (_openBlock(), _createBlock(TariffPopup, {
          key: 1,
          description: popupDescription.value,
          title: popupTitle.value,
          onHide: _cache[0] || (_cache[0] = ($event: any) => (showTariffPopup.value = false))
        }, null, 8, ["description", "title"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})