import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/trash.png'


const _hoisted_1 = {
  key: 0,
  class: "selected-tariff"
}
const _hoisted_2 = { class: "selected-tariff__left" }
const _hoisted_3 = { class: "selected-tariff__date" }
const _hoisted_4 = { class: "selected-tariff__day" }
const _hoisted_5 = { class: "selected-tariff__date-inner" }
const _hoisted_6 = { class: "selected-tariff__month" }
const _hoisted_7 = { class: "selected-tariff__week-day" }
const _hoisted_8 = { class: "selected-tariff__right" }
const _hoisted_9 = { class: "selected-tariff__title" }
const _hoisted_10 = { class: "selected-tariff__text" }
const _hoisted_11 = { class: "selected-tariff__text" }
const _hoisted_12 = { class: "selected-tariff__buttons" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["onClick"]

import { ref } from 'vue';
import { useBookingStore } from '@/store/booking';
import AddChildrenPopup from '@/components/AddChildrenPopup.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectedTariffs',
  setup(__props) {

const bookingStore = useBookingStore();
const showChildrenPopup = ref(false);

const uidForChildrenPopup = ref('');

const addChildren = (uid: string) => {
  showChildrenPopup.value = true;
  uidForChildrenPopup.value = uid;
};
const delChildren = (uid: string) => {
  bookingStore.delChildren(uid);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(bookingStore).orderGroups.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(bookingStore).orderGroups, (orderGroup) => {
            return (_openBlock(), _createElementBlock("div", {
              key: orderGroup.dayNumber + orderGroup.monthNumber,
              class: "selected-tariff__inner"
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, _toDisplayString(orderGroup.dayNumber), 1),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, _toDisplayString(orderGroup.monthNumber), 1),
                    _createElementVNode("div", _hoisted_7, _toDisplayString(orderGroup.weekDay), 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(orderGroup.orders, (order) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: order.tariffId,
                    class: "selected-tariff__card"
                  }, [
                    _createElementVNode("div", _hoisted_9, _toDisplayString(order.title), 1),
                    _createElementVNode("div", _hoisted_10, [
                      _cache[1] || (_cache[1] = _createElementVNode("span", null, "Посещение:", -1)),
                      _createTextVNode(" " + _toDisplayString(order.time), 1)
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _cache[2] || (_cache[2] = _createElementVNode("span", null, "Стоимость:", -1)),
                      _createTextVNode(" " + _toDisplayString(order.price) + " ₽ ", 1)
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      (order.children < 2 && order.adults === 1)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: "selected-tariff__button",
                            onClick: ($event: any) => (addChildren(order.uid))
                          }, " Добавить детский тариф ", 8, _hoisted_13))
                        : _createCommentVNode("", true),
                      (order.children < 4 && order.adults === 2)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: "selected-tariff__button",
                            onClick: ($event: any) => (addChildren(order.uid))
                          }, " Добавить детский тариф ", 8, _hoisted_14))
                        : _createCommentVNode("", true),
                      (
                (order.children && order.adults === 1)
                ||
                (order.children > 1 && order.adults > 1)
              )
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 2,
                            class: "selected-tariff__button",
                            onClick: ($event: any) => (delChildren(order.uid))
                          }, " Удалить детский тариф ", 8, _hoisted_15))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", {
                      class: "selected-tariff__delete",
                      onClick: ($event: any) => (_unref(bookingStore).delTariff(order.uid))
                    }, _cache[3] || (_cache[3] = [
                      _createElementVNode("img", {
                        src: _imports_0,
                        alt: "Удалить тариф"
                      }, null, -1)
                    ]), 8, _hoisted_16)
                  ]))
                }), 128))
              ])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (showChildrenPopup.value)
      ? (_openBlock(), _createBlock(AddChildrenPopup, {
          key: 1,
          uid: uidForChildrenPopup.value,
          onHide: _cache[0] || (_cache[0] = ($event: any) => (showChildrenPopup.value = false))
        }, null, 8, ["uid"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})